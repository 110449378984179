import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalOptions } from 'app/common/models/models';
import { GroupItem } from 'app/services/ingrediant.service';
import { PopupInfoComponent } from '../../popup-info/popup-info.component';
import { ModalService } from 'app/common/services/modal.service';

@Component({
	selector: 'environmental-banding-detail',
	templateUrl: './environmental-banding-detail.component.html',
	styleUrls: ['environmental-banding-detail.component.scss']
})
export class EnvironmentalBandingDetailComponent implements OnInit, AfterViewInit{
	@Input("item") item: GroupItem;
	pageLoaded = false;

	measurementInGrams= true;
	insertLabelMeasurementUnit = "";
	measurementUnit = "";
	sustainabilityIndex = false;
	sustainabilityIndexDescription = "";
	sustainabilityColor = "";
	carbonFootprintReference;
	carbonFootprintReferenceLabel;
	carbonFootprintColor;
	waterFootprintReference;
	waterFootprintColor;
	constructor(private _modalService: ModalService) {

	}
	_quantity = 1000;
	get quantity() {
		return this._quantity;
	}
	set quantity(v) {
		this._quantity = v;
	}
	static index = 0;
	classes = ["A", "B", "C", "D", "E"];



	get carbonFootprint() {
		return (this.item.carbonFootprint/1000) * this._quantity;
	}
	get waterFootprint() {
		return (this.item.waterFootprint/1000) * this._quantity;
	}
	get fishQualitySustainability() {
		return this.classes[EnvironmentalBandingDetailComponent.index++ % 5];
	}
	ngAfterViewInit(): void {
		
	}



	// get insertLabelMeasurementUnit() {
		// return (this.measurementInGrams)? : "Insert the ml";
	// }

	ngOnInit() {
		if (this.item && this.item.unitsWaterfootprint == "liters water/kg") {
			this.measurementInGrams = true;
			this.insertLabelMeasurementUnit = "Insert the grams";
			this.measurementUnit = "g.";
			this.carbonFootprintReferenceLabel = "Calculated for 1Kg of item"
		}
		else {
			this.measurementInGrams = false;
			this.insertLabelMeasurementUnit = "Insert the ml";
			this.measurementUnit = "ml.";
			this.carbonFootprintReferenceLabel = "Calculated for 1l of item"
		}
		if (this.item && this.item.blueSustainabilityIndex != null && this.item.blueSustainabilityIndex!= undefined && this.item.blueSustainabilityIndex != "") {
			this.sustainabilityIndex = this.item.blueSustainabilityIndex;
			switch (this.item.blueSustainabilityIndex) {
				case "LIMIT":
					this.sustainabilityIndexDescription = "Limited consumption suggested, i.e. less than 1 per week.";
					this.sustainabilityColor = "#e63525";
					break;
				case "MODERATE":
				case "MODERATE/ LIMIT":
					this.sustainabilityIndexDescription = "Moderate consumption suggested, 2-3 times per week as in SWITCH diet";
					this.sustainabilityColor = "#fab726";
					break;
				case "OPTIMAL":
					this.sustainabilityIndexDescription = "Optimal consumption, no main limitation at present";
					this.sustainabilityColor = "#79b936";
					break;
				default:
					break;
			}
		}
		this.carbonFootprintReference = this.item.carbonFootprint;
		this.waterFootprintReference = this.item.waterFootprint;
		this.carbonFootprintColor = this.colors[this.item.carbonFootprintBanding];
		this.waterFootprintColor = this.colors[this.item.waterFootprintBanding];
		this.pageLoaded = true;
	}

	

	colors = {
		"Orange": "#f39202",
		"Yellow": "#ffde01",
		"Dark green": "#007518",
		"Light green": "#95c11f",
		"Red": "#dd1e00"
	}

	showInfo = (infoId) => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {}
		}
		let data = {
			sectionId: infoId
		}
		this._modalService.show(PopupInfoComponent, data, modalOption);
	}
	
}